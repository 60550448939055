<template>
  <v-expand-transition>
    <v-card
        v-if="show"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%;"
    >
      <v-card-text class="pb-0" style="opacity: 0.5;">

        <v-virtual-scroll
            :items="items"
            :item-height="65"
            height="150"
        >
          <template v-slot:default="{ item }">

            <v-list-item>

              <v-list-item-avatar style="opacity: 0.5" tile>
                <v-img :src="get_img()"/>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title>
                  <div style="display:inline-block">{{ item.name ? item.name : 'Аноним' }} </div>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.commentUser ? item.commentUser : 'Без комментария' }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <!--                <v-btn depressed small>-->
                <!--                  <v-icon color="orange darken-4" right>mdi-open-in-new</v-icon>-->
                <!--                </v-btn>-->
                <div>{{ item.value }}</div>
                <div style="font-size: xx-small">{{ item.create|date }}</div>
              </v-list-item-action>
            </v-list-item>

            <v-divider/>

          </template>
        </v-virtual-scroll>

        <!--        <v-list two-line>-->
        <!--          <template v-for="(item, index) in items">-->

        <!--            <v-divider-->
        <!--                v-if="item.divider"-->
        <!--                :key="index"-->
        <!--                :inset="item.inset"-->
        <!--            ></v-divider>-->

        <!--            <v-list-item-->
        <!--                v-else-->
        <!--                :key="item.title"-->
        <!--            >-->
        <!--              <v-list-item-avatar>-->
        <!--                <v-img :src="item.avatar"></v-img>-->
        <!--              </v-list-item-avatar>-->

        <!--              <v-list-item-content>-->
        <!--                <v-list-item-title v-html="item.title"></v-list-item-title>-->
        <!--                <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>-->
        <!--              </v-list-item-content>-->
        <!--            </v-list-item>-->
        <!--          </template>-->
        <!--        </v-list>-->


      </v-card-text>

      <v-card-actions class="pt-1">
        <v-btn
            x-small text
            color="teal accent-4"
            @click="show = false"
        >
          Close
        </v-btn>
      </v-card-actions>

      <v-overlay
          absolute
          :opacity=0.8
          color="white"
          :value="loading"
      >
        <v-progress-circular
            indeterminate
            size="30"
            color="teal"
        ></v-progress-circular>
      </v-overlay>


    </v-card>
  </v-expand-transition>
</template>

<script>
export default {
  props: {
    open: Boolean,
    player: Object,
  },
  data() {
    return {
      loading: true,
      items: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: '',
          messCode: '',
          commentUser: 'Сообщение от пользователя',
          create: '2022-02-01T18:18:41.827171Z',
          value: 1099
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Player1',
          messCode: '',
          commentUser: '',
          create: '2022-02-01T18:18:41.827171Z',
          value: -101
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Player1',
          messCode: '',
          commentUser: 'Сообщение от пользователя',
          create: '2022-02-01T18:18:41.827171Z',
          value: 10100
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Player1',
          messCode: '',
          commentUser: 'Сообщение от пользователя',
          create: '2022-02-01T18:18:41.827171Z',
          value: 1
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Player1',
          messCode: '',
          commentUser: 'Сообщение от пользователя',
          create: '2022-02-01T18:18:41.827171Z',
          value: 101
        },
      ],
    }
  },
  computed: {
    show: {
      get() {
        if (this.open) this.form_init()
        return this.open
      },
      set(value) {
        if (!this.open) return // отрабатываем только закрытие формы
        this.$emit('update:open', value) // чтобы родитель изменил переменную
        // this.form_close()
      }
    }
  },
  methods: {
    get_img() {
      return require('@/assets/avatar2.svg')
      // if (this.product.type === 'PRD') {
      //   return require('@/views/shop/assets/5.svg')
      // } else {
      //   return require('@/views/shop/assets/clock.svg')
      // }
    },
    load_karma_log() {
      this.$axios.get(this.$url.server_user_karma.replace('55555', this.player.id))
          .then(response => {
            this.items = response.data
            this.loading = false
          })
    },
    form_init() {
      // вызывается каждый раз при открытии формы
      // if (this.open && this.loading) // подгрузка один раз
        this.load_karma_log()
    },
    // form_close() {
    //   //Очищаем форму
    //   Object.assign(this.$data, normal_data);
    // },
  }
}
</script>

<style scoped>

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.v-card__actions {
  box-shadow: 0px -2px 7px -4px rgba(34, 60, 80, 0.6);
}

</style>